
import { defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import Multiselect from "@vueform/multiselect";
import $ from "jquery";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    Multiselect
  },
  setup: function() {
    const store = useStore();
    const router = useRouter();
    onBeforeMount(() => {
      if (JwtService.getToken()) {
        router.push({ name: "dashboard2" });
      }
    });

    onMounted(() => {
      //$("#employee .multiselect-search input").attr('autocomplete', 'new-password');
    });

    const submitButton = ref<HTMLElement | null>(null);
    const authUsersList = ref({
      //value: [],
      mode: "single",
      placeholder: "Select employees",
      searchable: true,
      trackBy: "fullname",
      label: "fullname",
      resolveOnLoad: false,
      value: "simanov",
      options: []
    });

    let lastLoggedInAs = window.localStorage.getItem("lastLoggedInAs") || "";

    const onUserSelect = value => {
      lastLoggedInAs = value;
      window.localStorage.setItem("lastLoggedInAs", value);
    };

    /*ApiService.setHeader();
    ApiService.get("https://cmms.onecorp.cloud/api/v2/auth/users")
      .then(data => {
        //console.log(data.data);
        //console.log(data.data.data);
        //update employees list
        authUsersList.value.options = data.data.data;
        //console.log(authUsersList.value);

        //autoselect last logged in user
        if (lastLoggedInAs !== "") {
          authUsersList.value.value = lastLoggedInAs;
        }
      })
      .catch(response => {
        //console.log(response);
      });
*/
    /*Promise.resolve(window.localStorage.getItem("usersList") || "{}").then(JSON.parse).then((employees) => {
      authUsersList.value.options = employees;
      if (lastLoggedInAs !== "") {
        authUsersList.value.value = lastLoggedInAs;
      }
    });*/

    //this.context.commit(Mutations.SET_AUTH, data);

    //Create form validation object
    const login = Yup.object().shape({
      password: Yup.string()
        .min(4)
        .required()
        .label("Password")
    });

    //console.log(login);

    //Form submit function
    const onSubmitLogin = values => {
      //values.username = lastLoggedInAs;

      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      /*if (submitButton.value) {
        // Activate indicator
        submitButton.value!.setAttribute("data-kt-indicator", "on");
      }*/
      //console.log((<HTMLInputElement>document.getElementsByName("password")[0]).value);
      if ((<HTMLInputElement>document.getElementsByName("password")[0]).value === "1234") {
      //window.localStorage.setItem("signed_in", "yes");
        window.localStorage.setItem("signed_in", "yes");
        Swal.fire({
          text: "You signed in successfully! Redirecting to dashboard...",
          icon: "success",
          timer: 1000,
          buttonsStyling: false,
          confirmButtonText: "Redirect now",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary"
          }}).then(function() {
          //console.log(!!JwtService.getToken());
          // Go to page after successfully login
          router.push({ name: "dashboard2" });
        });
      } else {
          Swal.fire({
            text: "Wrong password. Please, try again",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
      }
      // Send login request
      //console.log(values);
      /*store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          Swal.fire({
            text: "You signed in successfully! Redirecting to dashboard...",
            icon: "success",
            timer: 1000,
            buttonsStyling: false,
            confirmButtonText: "Redirect now",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          }).then(function() {
            //console.log(!!JwtService.getToken());
            // Go to page after successfully login
            router.push({ name: "dashboard2" });
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        });*/

      //Deactivate indicator
      //submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      onSubmitLogin,
      onUserSelect,
      login,
      submitButton,
      authUsersList
    };
  }
});
