<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="#" class="mb-12">
        <img
          alt="Logo"
          src="media/logos/spektrgroup.png"
          class="h-35px"
        />
      </a>
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100"
          id="kt_login_signin_form"
          @submit="onSubmitLogin"
          :validation-schema="login"
        >
          <!--begin::Heading-->
          <div class="text-center mb-10">
            <!--begin::Title-->
            <h1 class="text-dark mb-3">
              Welcome to MyAssets
            </h1>
            <!--end::Title-->
            <h4>Owned and managed by SpektrGroup</h4>
          </div>
          <!--begin::Heading-->

          <div class="mb-10 bg-light-info p-8 rounded d-none">
            <div class="text-info">
              Use account <strong>admin@demo.com</strong> and password
              <strong>demo</strong> to continue.
            </div>
          </div>

          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <!--begin::Wrapper-->
            <div class="d-flex flex-stack mb-2">
              <!--begin::Label-->
              <label class="form-label fw-bolder text-dark fs-6 mb-0"
                >Password</label
              >
              <!--end::Label-->

              <!--begin::Link-->
              <router-link
                to="/password-reset"
                class="link-primary fs-6 fw-bolder d-none"
              >
                Forgot Password ?
              </router-link>
              <!--end::Link-->
            </div>
            <!--end::Wrapper-->

            <!--begin::Input-->
            <Field
                class="form-control form-control-lg form-control-solid"
                style="position: absolute; opacity: 0; width: 1px; height: 1px;"
                type="text"
                name="username"
                autocomplete="on"
            />
            <Field
              id="password"
              class="form-control form-control-lg form-control-solid"
              type="password"
              name="password"
              autocomplete="on"
            />
            <!--end::Input-->
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="password" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Actions-->
          <div class="text-center">
            <!--begin::Submit button-->
            <button
              type="submit"
              ref="submitButton"
              id="kt_sign_in_submit"
              class="btn btn-lg btn-primary w-100 mb-5"
            >
              <span class="indicator-label">
                Continue
              </span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Submit button-->
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <div class="d-flex align-items-center fw-bold fs-6 d-none">
        <a href="#" class="text-muted text-hover-primary px-2">About</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact Us</a>
      </div>
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import Multiselect from "@vueform/multiselect";
import $ from "jquery";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    Multiselect
  },
  setup: function() {
    const store = useStore();
    const router = useRouter();
    onBeforeMount(() => {
      if (JwtService.getToken()) {
        router.push({ name: "dashboard2" });
      }
    });

    onMounted(() => {
      //$("#employee .multiselect-search input").attr('autocomplete', 'new-password');
    });

    const submitButton = ref<HTMLElement | null>(null);
    const authUsersList = ref({
      //value: [],
      mode: "single",
      placeholder: "Select employees",
      searchable: true,
      trackBy: "fullname",
      label: "fullname",
      resolveOnLoad: false,
      value: "simanov",
      options: []
    });

    let lastLoggedInAs = window.localStorage.getItem("lastLoggedInAs") || "";

    const onUserSelect = value => {
      lastLoggedInAs = value;
      window.localStorage.setItem("lastLoggedInAs", value);
    };

    /*ApiService.setHeader();
    ApiService.get("https://cmms.onecorp.cloud/api/v2/auth/users")
      .then(data => {
        //console.log(data.data);
        //console.log(data.data.data);
        //update employees list
        authUsersList.value.options = data.data.data;
        //console.log(authUsersList.value);

        //autoselect last logged in user
        if (lastLoggedInAs !== "") {
          authUsersList.value.value = lastLoggedInAs;
        }
      })
      .catch(response => {
        //console.log(response);
      });
*/
    /*Promise.resolve(window.localStorage.getItem("usersList") || "{}").then(JSON.parse).then((employees) => {
      authUsersList.value.options = employees;
      if (lastLoggedInAs !== "") {
        authUsersList.value.value = lastLoggedInAs;
      }
    });*/

    //this.context.commit(Mutations.SET_AUTH, data);

    //Create form validation object
    const login = Yup.object().shape({
      password: Yup.string()
        .min(4)
        .required()
        .label("Password")
    });

    //console.log(login);

    //Form submit function
    const onSubmitLogin = values => {
      //values.username = lastLoggedInAs;

      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      /*if (submitButton.value) {
        // Activate indicator
        submitButton.value!.setAttribute("data-kt-indicator", "on");
      }*/
      //console.log((<HTMLInputElement>document.getElementsByName("password")[0]).value);
      if ((<HTMLInputElement>document.getElementsByName("password")[0]).value === "1234") {
      //window.localStorage.setItem("signed_in", "yes");
        window.localStorage.setItem("signed_in", "yes");
        Swal.fire({
          text: "You signed in successfully! Redirecting to dashboard...",
          icon: "success",
          timer: 1000,
          buttonsStyling: false,
          confirmButtonText: "Redirect now",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary"
          }}).then(function() {
          //console.log(!!JwtService.getToken());
          // Go to page after successfully login
          router.push({ name: "dashboard2" });
        });
      } else {
          Swal.fire({
            text: "Wrong password. Please, try again",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
      }
      // Send login request
      //console.log(values);
      /*store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          Swal.fire({
            text: "You signed in successfully! Redirecting to dashboard...",
            icon: "success",
            timer: 1000,
            buttonsStyling: false,
            confirmButtonText: "Redirect now",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          }).then(function() {
            //console.log(!!JwtService.getToken());
            // Go to page after successfully login
            router.push({ name: "dashboard2" });
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        });*/

      //Deactivate indicator
      //submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      onSubmitLogin,
      onUserSelect,
      login,
      submitButton,
      authUsersList
    };
  }
});
</script>
